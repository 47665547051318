<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
            v-model="query.status"
            clearable
            placeholder="状态"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option
              v-for="item in statusComputedOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
    <el-input
            v-model="query.likeName"
            clearable placeholder="客户昵称/MT4/活动名称" style="width: 300px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <div class="filter-item">
      到期时间：
      <el-date-picker
        style="width: 150px;"
        v-model="query.startTime"
        type="date"
        placeholder="开始日期">
      </el-date-picker>
      至
      <el-date-picker
        style="width: 150px;"
        v-model="query.endTime"
        type="date"
        placeholder="结束日期">
      </el-date-picker>
    </div>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-close" @click="clear">清空</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import { mapGetters } from 'vuex'
export default {
  props: {
    query: {
      type: Object,
      required: true
    },
    isCredits:{
      default: false
    }
  },
  data() {
    return {
      statusOptions: [
        {
          display_name: '待审核',
          key: 0,
        },
        {
          display_name: '已结束',
          key: 1,
        },
        {
          display_name: '已拒绝',
          key: 2,
        },
        {
          display_name: '进行中',
          key: 3,
        },
        {
          display_name: '已过期',
          key: 4,
        },
        {
          display_name: '已冻结',
          key: 5,
        },
        {
          display_name: '已完成',
          key: 6,
        },
        {
          display_name: '已取消',
          key: 7,
        },
        {
          display_name: '转出审核中',
          key: 8,
        }
      ]
    }
  },
  computed:{
    statusComputedOptions(){
      if(!this.isCredits){
        return this.statusOptions;
      }else {
        return [
          {
            display_name: '待审核',
            key: 0,
          },
          {
            display_name: '已通过',
            key: 1,
          },
          {
            display_name: '已拒绝',
            key: 2,
          },
        ];
      }
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    },
    clear(){
      this.$emit('clear');
    }
  }
}
</script>
